import {useMemo} from 'react'
import {useSearchParams} from 'react-router-dom'

export function CustomError() {
  const [query] = useSearchParams()

  const decryptedMessage = useMemo(() => {
    const message = query?.get('message')
    if (message) {
      return atob(message)
    }
  }, [query])

  return (
    <div className={`d-flex flex-column`}>
      <div className='alert alert-danger' style={{marginTop: '10px'}}>
        <div className='alert-text font-weight-bold'>{decryptedMessage}</div>
      </div>
      <div className='mb-4 mt-10 d-flex justify-content-center'>
        <img width='200px' src='/media/logos/logo.png' alt='' />
      </div>
    </div>
  )
}
