import {Navigate, Route, Routes} from 'react-router-dom'
import {Registration} from '../registration/Registration'
import {Login} from './components/Login'

const AuthPage = () => (
  <Routes>
    <Route>
      <Route path='registration/:token' element={<Registration />} />
      <Route path='login' element={<Login />} />
      <Route path='*' element={<Navigate to='digitalcard' />} />
    </Route>
  </Routes>
)

export {AuthPage}
