import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/query'
import {generatedApi} from './api/api'

export const store = configureStore({
  reducer: {
    [generatedApi.reducerPath]: generatedApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([generatedApi.middleware]),
})

setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
