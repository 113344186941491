import {Dispatch, SetStateAction} from 'react'

export type PaginationState = {
  page: number
  items_per_page: number
  links?: Array<{label: string; active: boolean; url: string | null; page: number | null}>
}

export type SortState = {
  sort?: string
  order?: 'ASC' | 'DESC'
}

export type FilterState = {
  filter?: {
    status?: 'QR' | 'ACTIVE' | 'INSTALLED' | 'ERROR'
  }
}

export type SearchState = {
  search?: string
}

export type Response<T> = {
  data?: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type QueryState = PaginationState & SortState & FilterState & SearchState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 0,
  items_per_page: 10,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: ''}

export type ListViewContextProps = {
  selected: Array<string>
  onSelect: (selectedId: string) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: string
  setItemIdForUpdate: Dispatch<SetStateAction<string | undefined>>
  isAllSelected: boolean
  disabled: boolean
  generateDialogOpen: boolean
  setGenerateDialogOpen: Dispatch<SetStateAction<boolean>>
  idToDelete?: string
  setIdToDelete: Dispatch<SetStateAction<string | undefined>>
  idToResend?: string
  setIdToResend: Dispatch<SetStateAction<string | undefined>>
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  isAllSelected: false,
  disabled: false,
  generateDialogOpen: false,
  setGenerateDialogOpen: () => {},
  setIdToDelete: () => {},
  setIdToResend: () => {},
}
