/* eslint-disable jsx-a11y/anchor-is-valid */
import {Autocomplete, TextField} from '@mui/material'
import {skipToken} from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useMemo, useState} from 'react'
import {useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {REQUIRED_FIELD} from '../../../lib/constants'
import {
  useGetTokenInfoQuery,
  useRegisterCustomerByQrMutation,
  useWalletSalesPersonQuery,
} from '../../api/backendApi'
import {ReactComponent as AppleWallet} from '../../images/apple-wallet.svg'
import {ReactComponent as GoogleWallet} from '../../images/google-wallet.svg'

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .required(REQUIRED_FIELD)
    .email()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  phone: Yup.string()
    .required(REQUIRED_FIELD)
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|\+?([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'Phone number is not valid'
    ),
  firstName: Yup.string().required(REQUIRED_FIELD),
  lastName: Yup.string().required(REQUIRED_FIELD),
  zipCode: Yup.string()
    .required(REQUIRED_FIELD)
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits'),
})

type FormValues = {
  email: string
  phone: string
  firstName: string
  lastName: string
  packet: string
  salesPerson: string | null
  zipCode: string
}

const initialValues: FormValues = {
  email: '',
  phone: '',
  firstName: '',
  lastName: '',
  packet: '',
  zipCode: '',
  salesPerson: null,
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Registration() {
  const {token} = useParams<{token: string}>()
  const {data: organization} = useGetTokenInfoQuery(token ? {token} : skipToken)
  const [loading, setLoading] = useState(false)
  const [register, {isSuccess, data, isLoading: registering}] = useRegisterCustomerByQrMutation()
  const [newWindow, setNewWindow] = useState<Window>()
  const {data: sellers} = useWalletSalesPersonQuery(
    organization && organization.id ? {walletId: organization.id, query: ''} : skipToken
  )

  const sellersOptions = useMemo(
    () =>
      sellers?.map(({name, packet}) => ({
        label: name,
        packet,
      })),
    [sellers]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      if (token && !registering) {
        setLoading(true)

        register({
          token,
          registerCustomerRequest: {
            firstName: values.firstName,
            lastName: values.lastName,
            telephone: values.phone,
            email: values.email,
            salesPerson: values.salesPerson ?? undefined,
            packet: values.packet ?? undefined,
            zipCode: values.zipCode,
          },
        })
          .unwrap()
          .then(() => {
            console.log('Registered')
            setLoading(false)
          })
          .catch((e) => {
            console.log('Register error')

            setStatus(
              'data' in e && 'message' in e.data && typeof e.data.message == 'string'
                ? e.data.message
                : 'The coupon details are incorrect'
            )
            setLoading(false)
          })
      }
    },
  })

  console.log(formik.getFieldProps('salesPerson'))

  return isSuccess && data ? (
    newWindow ? (
      <div className='col'>
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>
            Your card has been successfully added to your wallet and is ready to be used.
          </h1>
        </div>
      </div>
    ) : (
      <div className='col'>
        <div className='text-center mb-11'>
          <h3 className='text-dark fw-bolder mb-3'>{data.successMessage}</h3>
        </div>
        <div className='d-flex justify-content-center'>
          <a
            target='_blank'
            rel='noreferrer'
            onClick={() => {
              const newWindow = window.open(data.appleUrl)
              if (newWindow) setNewWindow(newWindow)
            }}
          >
            <AppleWallet height={48} width={180} />
          </a>
          <a
            target='_blank'
            rel='noreferrer'
            className='ms-8'
            onClick={() => {
              const newWindow = window.open(data.googleUrl)
              if (newWindow) setNewWindow(newWindow)
            }}
          >
            <GoogleWallet height={48} width={180} />
          </a>
        </div>
      </div>
    )
  ) : (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}

      {organization && (
        <div className='mb-5 d-flex flex-column align-items-center'>
          <h1
            className='fw-bolder mb-3 text-center py-4 px-5 grow-0'
            style={{
              color: organization.foregroundColor,
              backgroundColor: organization.backgroundColor,
              borderRadius: 12,
            }}
          >
            {organization.name}
          </h1>

          <img style={{maxWidth: '100%'}} src={organization.logoImageUrl} alt={organization.name} />
        </div>
      )}

      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-10 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : null}

      {/* begin::Form group */}
      <div className='fv-row mb-4'>
        <label className='form-label fs-6 fw-bolder text-dark required'>First Name</label>
        <input
          placeholder='First Name'
          {...formik.getFieldProps('firstName')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.firstName && formik.errors.firstName},
            {
              'is-valid': formik.touched.firstName && !formik.errors.firstName,
            }
          )}
          type='name'
          name='firstName'
          autoComplete='off'
        />
        {formik.touched.firstName && formik.errors.firstName && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.firstName}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-4'>
        <label className='form-label fs-6 fw-bolder text-dark required'>Last Name</label>
        <input
          placeholder='Last Name'
          {...formik.getFieldProps('lastName')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.lastName && formik.errors.lastName},
            {
              'is-valid': formik.touched.lastName && !formik.errors.lastName,
            }
          )}
          type='name'
          name='lastName'
          autoComplete='off'
        />
        {formik.touched.lastName && formik.errors.lastName && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.lastName}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-4'>
        <label className='form-label fs-6 fw-bolder text-dark required'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0 required'>Phone</label>
        <input
          type='phone'
          placeholder='Phone'
          autoComplete='off'
          {...formik.getFieldProps('phone')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.phone && formik.errors.phone,
            },
            {
              'is-valid': formik.touched.phone && !formik.errors.phone,
            }
          )}
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0 required'>Zip Code</label>
        <input
          type='text'
          placeholder='Zip Code'
          autoComplete='off'
          {...formik.getFieldProps('zipCode')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.zipCode && formik.errors.zipCode,
            },
            {
              'is-valid': formik.touched.zipCode && !formik.errors.zipCode,
            }
          )}
        />
        {formik.touched.zipCode && formik.errors.zipCode && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.zipCode}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Participants Name</label>
        <Autocomplete
          sx={{
            '& .MuiInputBase-root': {
              p: '0.775rem 43px 0.775rem 1rem',
              borderRadius: '0.475rem',
            },

            '& .MuiOutlinedInput-root .MuiInputBase-input': {
              p: 0,
              fontSize: '1.1rem',
              fontWeight: 500,
              lineHeight: 1.5,
              height: 'unset',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
          disablePortal
          isOptionEqualToValue={(option, value) => {
            return option.label === value
          }}
          id='combo-box-demo'
          options={sellersOptions ?? []}
          {...formik.getFieldProps('salesPerson')}
          onChange={(e, selectedObject) => {
            if (selectedObject !== null) {
              formik.setFieldValue('salesPerson', selectedObject.label)
              formik.setFieldValue('packet', selectedObject.packet)
            } else {
              formik.setFieldValue('salesPerson', null)
              formik.setFieldValue('packet', '')
            }
          }}
          renderInput={({InputProps, ...params}) => (
            <TextField
              {...params}
              InputProps={{
                ...InputProps,
                className: clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.salesPerson && formik.errors.salesPerson,
                  },
                  {
                    'is-valid': formik.touched.salesPerson && !formik.errors.salesPerson,
                  }
                ),
              }}
              type='text'
              placeholder='Participants Name'
            />
          )}
        />

        {formik.touched.salesPerson && formik.errors.salesPerson && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.salesPerson}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10 gap-3'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        {organization?.registrationMessage && (
          <div className='p-2'>
            <div className='text-info text-center'>{organization.registrationMessage}</div>
          </div>
        )}
        {organization?.registrationImageUrl && (
          <img
            width='100%'
            src={organization.registrationImageUrl}
            alt={organization?.registrationMessage}
          />
        )}
      </div>
      {/* end::Action */}
    </form>
  )
}
