import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {getAuth} from '../modules/auth'

export const generatedApi = createApi({
  reducerPath: 'generatedApi',
  tagTypes: ['User', 'LoginChanged', 'Certificates'],

  baseQuery: fetchBaseQuery({
    baseUrl: '/',
    prepareHeaders: (headers) => {
      const token = getAuth()

      if (token && token.basic) headers.set('Authorization', 'Basic ' + token.basic)

      // const csrftoken = getCookie("csrftoken")
      // if (csrftoken) headers.set("X-CSRFToken", csrftoken)
      return headers
    },
  }),
  endpoints: () => ({}),
})
